a{
    color: white;
    text-decoration: none;
}
a:hover{
    color: purple;
}
.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left:200px;
    margin-right:200px;
    margin-top: 20px;
    .social-media{
        display: flex;
        gap: 20px;
        .privacy-policy{
            font-size: smaller;
            padding-left: 5px;
        }
        &:hover{
            color: blue;
        }
    }
}

@media  screen and (max-width: 1025px){
    .footer{
        margin-left: 100px;
        margin-right: 100px;
    }
    
}

@media screen and (max-width: 821px){
    .footer{
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media screen and (max-width: 539px){
    .footer{
        flex-direction: column;
    }
}
