.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.large-button-text {
  font-size: 25px;
  font-weight: bolder;
  display: block;
}
.app {
  background-image: url("../src/image/whoslying-background 1.png");
  background-repeat: repeat-y;
  background-color: black;
  color: white;

  .main-page {
    padding-top: 100px;
    width: 70%;
    padding-left: 200px;
    .first-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 150px;
      .first-row-left {
        .header {
          font-size: 60px;
          font-family: ArticulatCF;
          font-weight: bold;
          background-color: #ae67fa;
          background-image: linear-gradient(
            89.97deg,
            #ae67fa 1.84%,
            #ffffff 102.67%
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
        }
        .paragraph {
          font-size: 28px;
          font-family: ArticulatCF;
          font-weight: normal;
          font-weight: lighter;
        }
        .buttons {
          display: flex;
          gap: 20px;
          .google-play-button {
            display: block;
            visibility: hidden;
          }
          button {
            background-color: inherit;
            color: white;
            border: 1px solid white;
            height: 60px;
            display: flex;
            align-items: center;
            width: 200px;
            border-radius: 10px;
            &:hover {
              background-color: white;
              color: black;
              border: 1px solid gray;
            }
            .apple-logo {
              font-size: 35px;
            }
            .google-play-logo {
              width: 25px;
            }
            .apple-text {
              padding-left: 10px;
              font-size: large;
              text-align: left;
            }
            .google-play-text {
              padding-left: 10px;
              text-align: left;
              font-size: large;
            }
          }
        }
      }
      .first-row-right {
        .hero-pic {
          width: 38vw;
        }
      }
    }
    .second-row {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: max-content;

      .second-row-left {
        .hero-pic {
          width: 33vw;
        }
      }
      .second-row-right {
        .header {
          font-size: 65px;
          width: 40vw;
          margin-left: 100px;
        }
      }
    }
  }
}
@media screen and (max-width: 1281px) {
  .app {
    .main-page {
      .first-row {
        .first-row-right {
          .hero-pic {
            width: 33vw;
          }
        }
      }
      .second-row {
        .second-row-right {
          .header {
            font-size: 45px;
          }
        }
        .second-row-left {
          .hero-pic {
            width: 30vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .large-button-text {
    font-size: 18px;
  }
  .app {
    .main-page {
      padding-left: 100px;
      .first-row {
        .first-row-left {
          .buttons {
            button {
              width: 150px;
              .apple-logo {
                font-size: 25px;
              }
              .google-play-logo {
                width: 20px;
              }
              .apple-text {
                font-size: small;
                padding-left: 5px;
              }
              .google-play-text {
                font-size: small;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 913px) {
  .app {
    padding-bottom: 18rem;
  }
}

@media screen and (max-width: 850px) {
  .app {
    padding-bottom: 90px;
    .main-page {
      padding-left: 50px;
      width: 90%;
      .first-row {
        gap: 50px;
        .first-row-left {
          .buttons {
            display: block;
          }
        }
        .first-row-right {
          .hero-pic {
            width: 50vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .app {
    .main-page {
      width: 90%;
      text-align: center;
      padding-left: 20px;
      .first-row {
        display: block;
        .first-row-left {
          .header {
            font-size: 45px;
          }
          .buttons {
            display: flex;
            justify-content: center;
            .google-play-button {
              display: none;
            }
          }
        }
        .first-row-right {
          .hero-pic {
            width: 90%;
          }
        }
      }
      .second-row {
        display: block;
        .second-row-right {
          .header {
            font-size: 30px;
          }
        }
        .second-row-left {
          display: flex;
          justify-content: center;
          // background-color: red;

          .hero-pic {
            width: 70vw;
            margin-left: 50%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 281px) {
  .large-button-text {
    font-size: 12px;
  }
  .app {
    .main-page {
      .first-row {
        .first-row-left {
          .buttons {
            button {
              .apple-text {
                font-size: smaller;
              }
              .google-play-text {
                font-size: smaller;
              }
            }
          }
        }
      }
      .second-row {
        .second-row-right {
          .header {
            margin-left: 35px;
          }
        }
      }
    }
  }
}
