.privacy-policy-section{
    background-image: url("../src/image/whoslying-background 1.png");
    background-repeat: repeat-y;
    background-color: black;
    padding-top: 50px;
    padding-bottom: 50px;
    color: white;
        .privacy-policy{
        margin-left: 200px;
        margin-right: 200px;
        color: white;
        .back-arrow{
            color: white;
        }
    
    }

}

@media screen and (max-width: 540px) {
    .privacy-policy-section{
        .privacy-policy{
            margin-left: 50px;
            margin-right: 50px;
        }

    }
    
}