.navlink{
    text-decoration: none;
    
}
.navbar{
    display: flex;
    justify-content: space-between;
    background: url("../../image/navbar-background 1.png");
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100px;
    font-family: ArticulatCF;
    font-weight: normal;
  
    align-items: center;
    .logo{
        margin-left: 170px;
        .nav-logo{
            width: 200px;
        }
    }
    .navbar-right-mobile{
        display: none;
    }
    .navbar-right{
        display: flex;
        gap: 40px;
        list-style: none;
        width: 100%;
        // background-color: red;
        padding-right: 200px;
       
        li{
            color: white;
           &:hover{
            color:#AE67FA;
        }
        }

    }
}

@media screen and (max-width:1025px){
    .navbar{
        display: flex;
        align-items: center;
        .logo{
            margin-left: 75px;
        }
        .navbar-right{
            padding-right: 100px;
        }
    }
    
}





@media screen and (max-width: 850px){
    .navbar{
        padding-left: 0px;
        padding-right: 0px;
        .logo{
            margin-left: 25px;
        }
        .navbar-right{
            padding-right: 50px;
        }
        
      
    }
}
@media screen and (max-width: 580px){
    .navbar{
        position: fixed;
        display: flex;
        align-items: center;
        margin:0px;
        padding: 0px;
        width: 100%;
        .logo{
            margin-left: 0px;
        }
        .navbar-right{
            display: none;
        }
        .navbar-right-mobile{
            display: block;
            padding-top: 50px;
            margin-top: 0px;
           
            .hamburger-icon{
                margin-top: 0px;
                position: absolute;
                top: 35px;
                right: 50px;
            }
         
        }
        .mobile-slidedown{
            background-color: black;
            list-style: none;
            position: absolute;
            left: 0px;
            top: 55px;
            width: 100%;
            max-width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            
        }
        .navbar-right-mobile{
            .hamburger-icon{
                right: 20px;
            }
        }
      
      
        .mobile-slidedown{
            background-color: black;
            list-style: none;
            position: absolute;
            left: 0px;
            top: 55px;
            width: 100%;
            max-width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            .mobile-links{
                margin-bottom: 15px;
                font-size: larger;
            }
            
        }
    }
    
}